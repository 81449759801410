import { useContext, useState, useEffect } from "react";
import { MyContext } from "../data/contextapi";
import InfoIcon from "./InfoIcon";

export default function Door() {
  const {
    type,
    settype,
    setStep,
    width,
    setWidth,
    doorConfig,
    setDoorConfig,
    sidePannel,
    setSidePannel,
    sidePannelConfig,
    setSidePannelConfig,
    sidePannelSize,
    setSidePannelSize,
    stalenPart,
    setStalenPart,
    stalenType,
    setStalenType,
    open,
    inprogress,
    setInprogress,
    height,
    setHeight,
    holeWidth,
    setHoleWidth,
  } = useContext(MyContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check the window width
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    // Initial check
    checkScreenWidth();

    // Add event listener to check screen width on resize
    window.addEventListener("resize", checkScreenWidth);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (
      (doorConfig == "dubbele" && sidePannel === "beide") ||
      sidePannel === "beide"
    ) {
      setSidePannelSize(width);
    } else {
      setSidePannelSize(width);
    }
    setWidth(90);
    setSidePannelSize(90);
    setHoleWidth(calculateHoleWidth());
  }, [doorConfig, sidePannelConfig, sidePannel]);

  const handleHeightChange = (e) => {
    if (e.target.value > 285) {
      return
    }
    const value = e.target.value;
    if (!isNaN(value)) {
      setHeight(value);
    }
  };

  const calculateHoleMinWidth = () => {
    if (
      (doorConfig != "dubbele" &&
        (sidePannel === "links" || sidePannel === "rechts")) ||
      (doorConfig == "dubbele" && sidePannel == "geen")
    ) {
      return 120;
    }

    if (doorConfig != "dubbele" && sidePannel === "beide") {
      return 180;
    }
    if (
      doorConfig == "dubbele" &&
      (sidePannel == "links" || sidePannel == "rechts")
    ) {
      return 180;
    }

    if (doorConfig == "dubbele" && sidePannel == "beide") {
      return 240;
    }

    return 60;
  };

  const calculateHoleMaxWidth = () => {
    if (
      (doorConfig != "dubbele" &&
        (sidePannel === "links" || sidePannel === "rechts")) ||
      (doorConfig == "dubbele" && sidePannel == "geen")
    ) {
      return 240;
    }

    if (doorConfig != "dubbele" && sidePannel === "beide") {
      return 360;
    }
    if (
      doorConfig == "dubbele" &&
      (sidePannel == "links" || sidePannel == "rechts")
    ) {
      return 360;
    }

    if (doorConfig == "dubbele" && sidePannel == "beide") {
      return 400;
    }

    return 120;
  };

  const calculateHoleWidth = () => {
    if (
      (doorConfig != "dubbele" &&
        (sidePannel === "links" || sidePannel === "rechts")) ||
      (doorConfig == "dubbele" && sidePannel == "geen")
    ) {
      return 180;
    }

    if (doorConfig != "dubbele" && sidePannel === "beide") {
      return 270;
    }
    if (
      doorConfig == "dubbele" &&
      (sidePannel == "links" || sidePannel == "rechts")
    ) {
      return 270;
    }

    if (doorConfig == "dubbele" && sidePannel == "beide") {
      return 360;
    }

    return 90;
  };

  const handleHoleWidth = (e) => {
    if (e.target.value > calculateHoleMaxWidth()) {
      return
    }
    setHoleWidth(e.target.value);
    setSidePannelSize(calculateSidePanelWidth(e.target.value, width));

    if (sidePannel == "geen") {
      setWidth(doorConfig === "dubbele" ? e.target.value / 2 : e.target.value);
    }
  };

  const calculateSidePanelWidth = (totalWidth, doorWidth) => {
    if (
      doorConfig != "dubbele" &&
      (sidePannel === "links" || sidePannel === "rechts")
    ) {
      return totalWidth - Number(doorWidth);
    }

    if (doorConfig == "dubbele" && sidePannel == "geen") {
      return 0;
    }

    if (doorConfig != "dubbele" && sidePannel === "beide") {
      return (totalWidth - Number(doorWidth)) / 2;
    }
    if (
      doorConfig == "dubbele" &&
      (sidePannel == "links" || sidePannel == "rechts")
    ) {
      return totalWidth - Number(doorWidth) * 2;
    }

    if (doorConfig == "dubbele" && sidePannel == "beide") {
      return (totalWidth - Number(doorWidth) * 2) / 2;
    }

    return 0;
  };

  return (
    <>
      {/* <div style={{ width: '100%' }}>
                <div style={{ margin: '5%', paddingTop: '0.5rem', display: 'flex', justifyContent: 'space-between', fontWeight: 'bolder', fontSize: 'normal' }}>
                    <span>Type Deur</span>
                    <div style={{ backgroundColor: 'white', borderRadius: '20px', width: 'auto', padding: '10px', margin: '0 0 0 10px' }}>
                        <span className="body-txt" style={{}} onClick={() => { setStep('door') }}>&euro; 0,00</span>
                    </div>
                </div>
            </div> */}
      <div
        style={{
          margin: "5%",
          paddingTop: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bolder",
          fontSize: "normal",
        }}
      >
        <span>Afmeting</span>
      </div>
      <div className="slider-container" style={{ margin: "5%" }}>
        <div className="">
          <p style={{ marginBottom: "-5px" }}>Hoogte</p>
        </div>
        <div
          className="slider-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ flex: 1 }}>
            <input
              type="range"
              min="180"
              max="285"
              value={height}
              onChange={handleHeightChange}
              className="slider"
              id="range1"
              style={{
                width: "100%",
                height: "8px",
                borderRadius: "5px",
                appearance: "none",
                background: "#F2F2F3", // Grey line
                outline: "none", // Remove default outline
                marginTop: "10px", // Adjust margin to separate from the text above
              }}
            />
          </div>
          <div
            style={{
              marginLeft: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              backgroundColor: "#F2F2F3",
              position: "relative",
              width: "5rem",
              fontSize: "small",
              border: "1px solid",
              borderRadius: "4px",
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                background: "transparent",
              }}
              value={height}
              onChange={handleHeightChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              cm
            </span>
          </div>
        </div>
      </div>
      <div
        className="slider-container"
        style={{
          margin: "5%",
          borderBottom: "1px solid #d1d1d1",
          paddingBottom: "15px",
        }}
      >
        <div className="">
          <p style={{ marginBottom: "-5px" }}>Breedte</p>
        </div>
        <div
          className="slider-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ flex: 1 }}>
            <input
              type="range"
              min={calculateHoleMinWidth()}
              max={calculateHoleMaxWidth()}
              value={holeWidth}
              onChange={handleHoleWidth}
              style={{
                width: "100%",
                height: "8px",
                borderRadius: "5px",
                appearance: "none",
                background: "#F2F2F3", // Grey line
                outline: "none", // Remove default outline
                marginTop: "10px", // Adjust margin to separate from the text above
              }}
            />
          </div>
          <div
            style={{
              marginLeft: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              backgroundColor: "#F2F2F3",
              position: "relative",
              width: "5rem",
              fontSize: "small",
              border: "1px solid",
              borderRadius: "4px",
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                background: "transparent",
              }}
              value={holeWidth}
              onChange={handleHoleWidth}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              cm
            </span>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "5%",
            paddingTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <InfoIcon title={"Deur type"} />
        </div>
      </div>
      <div
        className="door-content-2"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="door-content-2-1 door-content-custom-1"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridColumnGap: "10px",
            fontSize: "11px",
            margin: "0 5%",
            width: "100%",
          }}
        >
          <div
            className="custom-card-1"
            style={{
              position: "relative",
              margin: "5px",
              borderRadius: "5px",
            }}
            onClick={() => {
              if (!inprogress) settype("Taatsdeur");
            }}
          >
            <div
              className="main-img-div"
              style={{
                border: type === "Taatsdeur" ? "2px solid black" : "none",
                borderRadius: "10px",
                boxShadow: "-5px -2px 10px -2px rgba(0,0,0,0.6)",
                padding: "10px",
                backgroundColor: "white",
                aspectRatio: "1/1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={type === "Taatsdeur"}
                  readOnly
                  onChange={() => {
                    if (!inprogress) settype("Taatsdeur");
                  }}
                />
              </div>
              <center>
                <img
                  src="./images/doortypes/door_type_1.jpg"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "100%",
                    width: "80%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                />
              </center>
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: type === "Taatsdeur" ? "black" : "#d7cb6f",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  fontWeight: "normal",
                  textTransform: "uppercase",
                }}
              >
                Taatsdeur
              </span>
            </div>
            <div></div>
          </div>
          <div
            className="custom-card-1"
            style={{ position: "relative", margin: "5px", borderRadius: "5px" }}
            onClick={() => {
              if (!inprogress) settype("Schuifdeur");
            }}
          >
            <div
              className="main-img-div"
              style={{
                border: type === "Schuifdeur" ? "2px solid black" : "none",
                borderRadius: "10px",
                boxShadow: "-5px -2px 10px -2px rgba(0,0,0,0.6)",
                padding: "10px",
                backgroundColor: "white",
                aspectRatio: "1/1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={type === "Schuifdeur"}
                  readOnly
                  onChange={() => {
                    if (!inprogress) settype("Schuifdeur");
                  }}
                />
              </div>
              <center>
                <img
                  src="./images/doortypes/door_type_2.jpg"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "100%",
                    width: "80%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                />
              </center>
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: type === "Schuifdeur" ? "black" : "#d7cb6f",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  fontWeight: "normal",
                  textTransform: "uppercase",
                }}
              >
                SCHUIFDEUR
              </span>
            </div>
            <div></div>
          </div>
          <div
            className="custom-card-1"
            style={{ position: "relative", margin: "5px", borderRadius: "5px" }}
            onClick={() => {
              if (!inprogress) settype("Scharnier");
            }}
          >
            <div
              className="main-img-div"
              style={{
                border: type === "Scharnier" ? "2px solid black" : "none",
                borderRadius: "10px",
                boxShadow: "-5px -2px 10px -2px rgba(0,0,0,0.6)",
                padding: "10px",
                backgroundColor: "white",
                aspectRatio: "1/1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={type === "Scharnier"}
                  readOnly
                  onChange={() => {
                    if (!inprogress) settype("Scharnier");
                  }}
                />
              </div>
              <center>
                <img
                  src="./images/doortypes/door_type_3.jpg"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "100%",
                    width: "80%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                />
              </center>
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: type === "Scharnier" ? "black" : "#d7cb6f",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  fontWeight: "normal",
                  textTransform: "uppercase",
                }}
              >
                KOZIJN DEUR
              </span>
            </div>
            <div></div>
          </div>
          <div
            className="custom-card-1"
            style={{ position: "relative", margin: "5px", borderRadius: "5px" }}
            onClick={() => {
              settype("vast-stalen");
              setDoorConfig("enkele");
              setSidePannel("geen");
              setInprogress(false);
            }}
          >
            <div
              className="main-img-div"
              style={{
                border: type === "vast-stalen" ? "2px solid black" : "none",
                borderRadius: "10px",
                boxShadow: "-5px -2px 10px -2px rgba(0,0,0,0.6)",
                padding: "10px",
                backgroundColor: "white",
                aspectRatio: "1/1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={type === "vast-stalen"}
                  readOnly
                  onChange={() => {
                    settype("vast-stalen");
                    setDoorConfig("enkele");
                    setSidePannel("geen");
                    setInprogress(false);
                  }}
                />
              </div>
              <center>
                <img
                  src="./images/doortypes/door_type_4.jpg"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "100%",
                    width: "80%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                />
              </center>
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: type === "vast-stalen" ? "black" : "#d7cb6f",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  fontWeight: "normal",
                  textTransform: "uppercase",
                }}
              >
                Vast stalen
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <button
        className="volgende btn"
        onClick={() => {
          setStep("samenstling");
        }}
      >
        Volgende
      </button>
    </>
  );
}
