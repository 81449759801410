import React, { useContext, useEffect } from "react";
import { MyContext } from "../data/contextapi";
import Tooltip from "../../Tooltip";

const InfoIcon = ({ title, containerStyles }) => {
  const { bubbles } = useContext(MyContext);

  const bubbleText = bubbles?.find(({ name }) => name === title)
    ? bubbles.find(({ name }) => name === title).text
    : "";

  

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        ...containerStyles,
      }}
    >
      {title}
      <Tooltip message={bubbleText}>
      <span
        style={{
          borderRadius: "100%",
          backgroundColor: "#d7cb6f",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
          className="bi bi-info"
          viewBox="0 0 16 16"
        >
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
      </span>
      </Tooltip>
    </span>
  );
};

export default InfoIcon;
